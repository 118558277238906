.bp3-dialog.login {
    & > .bp3-dialog-header {
        padding-right: 15px; }

    & > .bp3-dialog-body {

        .bp3-callout {
            margin-bottom: 15px; }

        .bp3-input-group {
            margin: 10px 0; }

        .bp3-form-group {
            margin-bottom: 5px;

            .bp3-input-group {
                margin: 1px 0; }

            .bp3-form-helper-text {
                margin-top: 0;
                margin-right: 8px;
                text-align: right; } } }

    & > .bp3-dialog-footer {
        display: flex;
        align-items: center;

        .options {
            flex: 1;

            & > .bp3-checkbox {
                margin-bottom: 0; } } } }

.bp3-overlay-backdrop.auth-backdrop {
    background-color: rgba(255, 255, 255, 0.6); }

ul.module-list {
    .module {
        img {
            width: 24px; }
        .bp3-text-overflow-ellipsis {
            font-size: 1.3em; } }

    .system {
        padding: 0 5px;
        justify-content: center;
        align-items: center;

        img {
            width: 16px; } } }
