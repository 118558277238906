@import './variables';

.bp3-dialog.katal-dialog.debug-window {
    width: 90vw;
    height: 80vh;
    // z-index: 1000

    .bp3-dialog-body {
        display: flex;
        flex-direction: column;

        .bp3-tabs {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: stretch;

            .bp3-tab {
                padding: 0 5px;

                & > em {
                    font-size: 0.9em; } }

            .bp3-tab-panel[aria-hidden="false"] {
                flex: 1 0 0;
                display: flex;
                justify-content: stretch;
                position: relative;
                // overflow-x: hidden
                overflow-y: hidden;

                .http {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    overflow-x: hidden;

                    .http-input {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        & > * + * {
                            margin-left: 0.5rem; }

                        & > .bp3-button.bp3-intent-primary {
                            padding: 5px 18px; } }

                    .http-status, .http-result {
                        margin: 5px; }

                    .http-status {
                        border: 1px $gray3 dotted;
                        display: flex;
                        flex-direction: row;
                        padding: 2px 5px;

                        .detail {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            font-size: 0.9em; }

                        .controls {
                            .bp3-button {
                                @extend .bp3-minimal, .bp3-small; } } }

                    .http-result {
                        background-color: $light-gray3;
                        flex: 1 0 0;
                        overflow-x: hidden;
                        overflow-y: auto;
                        align-items: stretch;
                        justify-content: stretch;
                        // min-height: 100px

                        pre {
                            font-size: 0.9em;
                            margin: 0;
                            line-height: normal;
                            overflow-x: hidden; }

                        // code
                        //     display: flex
                        //     font-family: monospace
                        //     font-size: 0.9em
                        //     line-height: normal
                        //     padding: 0
                        //     margin: 0
                        //     text-overflow: ellipsis
 } }                        //     white-space: pre-wrap

                .loggers {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;

                    & > * {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 1px 5px;

                        &.disabled {
                            color: $gray4; }

                        &:nth-child(odd) {
                            background-color: $light-gray2; }

                        .bp3-control {
                            margin-bottom: 0; }

                        & > * + * {
                            margin-left: 0.5rem; }

                        & > .namespace {
                            flex: 1; }

                        & > .options {
                            .bp3-button {
                                @extend .bp3-small, .bp3-outlined; } } } }

                .logs {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    overflow: hidden;

                    & > * + * {
                        margin-top: 5px; }

                    & > .actions {
                        display: flex;
                        padding: 2px 0;

                        & > * + * {
                            margin-left: 12px; }

                        & .bp3-button {
                            @extend .bp3-small, .bp3-outlined; }

                        & .bp3-input-group {
                            @extend .bp3-small; } }

                    & > .list {
                        flex: 1;
                        border: 1px inset white;
                        overflow-y: auto;

                        & > .line {
                            display: flex;
                            padding: 1px 3px;
                            font-size: 0.95em;
                            line-height: normal;
                            align-items: center;

                            & > * + * {
                                margin-left: 5px; }

                            .type {
                                color: black;
                                padding: 1px 5px;
                                font-size: 0.9em;
                                text-transform: uppercase;
                                border-radius: 2px;
                                background-color: $gray4;
                                user-select: none;

                                &:is(.debug > *) {
                                    background-color: $gold5; }

                                &:is(.error > *) {
                                    background-color: $red1;
                                    color: white; }

                                &:is(.warn > *) {
                                    background-color: $orange1;
                                    color: white; }

                                &:is(.notice > *) {
                                    background-color: $cobalt3;
                                    color: white; }

                                &:is(.verbose > *) {
                                    background-color: $green2;
                                    color: white; } }

                            .ikon.level {
                                &:is(.error > *) {
                                    color: $red1; }

                                &:is(.warn > *) {
                                    color: $orange1; } }

                            // &.debug
                            //     background-color: adjust-color($gold5, $alpha: -0.7)
                            //     &:nth-child(even)
                            //         background-color: adjust-color($gold5, $alpha: -0.5)

                            // &.error
                            //     background-color: adjust-color($red4, $alpha: -0.7)
                            //     &:nth-child(even)
                            //         background-color: adjust-color($red4, $alpha: -0.5)

                            & > .date {
                                font-size: 0.95em;
                                font-family: monospace;
                                font-weight: bold;
                                user-select: none;
                                letter-spacing: -1px;

                                &::after {
                                    content: ']'; }
                                &::before {
                                    content: '['; } }
                            & > .message {
                                flex: 1;
                                overflow-x: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis; }

                            &:nth-child(even) {
                                background-color: rgba(0, 0, 0, 0.1); } } } } } } } }

.bp3-popover.debug {

    .bp3-menu {
        font-family: monospace;
        line-height: normal;

        li .bp3-menu-item {
            padding: 2px 5px;

            .bp3-fill {
                display: flex;
                align-items: flex-end;
                line-height: normal;

                & > .type {
                    flex: 1;
                    font-weight: bold; }

                & > .date {
                    margin-left: 8px;
                    font-size: 0.8em; } } } } }
