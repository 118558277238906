@import 'variables';

#root {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 100vh;
    max-width: 100vw;

    align-items: stretch;
    justify-content: stretch; }

#app-header {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    height: 38px;
    background-color: $cobalt5;

    position: relative;
    z-index: 10;

    & > .bp3-navbar-group {
        height: auto;
        line-height: normal;
        align-items: center;

        &.app-name-group {
            &.clickable {
                cursor: pointer; }

            .app-icon {
                margin-right: 8px; }

            .app-title {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                user-select: none;
                font-family: 'Roboto', sans-serif;

                .app-prefix {
                    display: none;
                    font-weight: 100;
                    font-size: 0.9em; }

                .app-name {
                    font-size: 26px;
                    font-weight: 900;

                    .app-version {
                        font-weight: 300; } }

                .app-prerelease {
                    font-size: 12px;
                    margin-bottom: 12px;
                    margin-left: 2px;
                    font-weight: 300; } } }

        &.header-content {
            flex: 1;

            .bp3-button {
                min-height: 24px;
                padding: 0.2rem 0.625rem; } } } }

#app-content {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: clip;
    position: relative;
    z-index: 5; }

@keyframes working-spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

#status-bar {
    position: relative;
    z-index: 10;

    background-color: $light-gray1;
    padding: 0 0.5rem;
    height: 28px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ikon.working {
        animation-name: working-spin;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        margin-left: 0.5rem; }

    & > .menu {
        justify-content: flex-start;

        & > * + * {
            margin-right: 0.5rem; } }

    & > .user {
        justify-content: flex-end;
        user-select: none;

        & > * + * {
            margin-left: 0.5rem; } }

    & > .status {}

    & > .menu, & > .user {
        flex: 1; }

    & > div {
        display: flex;
        align-items: center;

        &.menu, &.user {
            flex: 1; }

        &.menu {
            justify-content: flex-start;

            & > * + * {
                margin-right: 0.5rem; } }

        &.user {
            justify-content: flex-end;
            user-select: none;

            & > * + * {
                margin-left: 0.5rem; } }

        &.status {}
        .bp3-button.module-button {
            @extend .bp3-small, .bp3-minimal; }

        .bp3-spinner.loading-status {
            margin-left: 0.5rem; }

        & > button.bp3-button, & > .bp3-popover-wrapper > .bp3-popover-target > button.bp3-button {
            @extend .bp3-small, .bp3-minimal;
            min-width: 0;
            min-height: 0;

            &.logout, &.dev {
                padding: 0.125rem 1rem; } }

        .bp3-menu.module-menu {
            li > a {
                align-items: center; } } } }

.bp3-portal > .bp3-overlay-open {

    & .bp3-popover {
        &.status-popover {
            z-index: $status-bar-popover-zindex;
            position: relative; }

        &.io-popover {
            user-select: none;

            .bp3-popover-content > .bp3-card {
                padding: 8px;

                & > div {
                    width: 220px;
                    display: flex;
                    align-items: center;
                    padding: 1px 8px;

                    &:nth-child(even) {
                        background-color: rgba(0, 0, 0, 0.05); }

                    & > * + * {
                        margin-left: 3px; }

                    & > .label {
                        flex: 1; }

                    & > .ikon.success {
                        color: $pt-intent-success; }

                    & > .ikon.fail {
                        color: $pt-intent-warning; } } } } } }
