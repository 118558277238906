@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "variables";
@import "@blueprintjs/core/src/blueprint.scss";
@import "katal-dialog";
@import "auth-dialog";
@import "debug-window";
@import "app-layout";

body {
	padding: 0;
	margin: 0;
	height: 100vh;
	max-height: 100vh;
	display: flex;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: $black; }

h1, h2, h3, h4, h5, h6 {
	font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 700; }

button {
	font-family: inherit; }

.debug {
	// display: none !important

	&.popup {
		// font-size: 0.7em
		display: block;
		position: absolute;
		border: $gold3 dotted 1px;
		background-color: $gold5;
		z-order: 10000;
		padding: 2px 8px;
		color: $white;
		user-select: none; }

	&.legend {
		display: inline;
		font-size: 11px;
		color: $gray5; } }

.better {
	color: $better; }

.worst {
	color: $worst; }

a {
	color: inherit; }

a:hover {
	color: inherit;
	text-decoration: inherit; }

.spacer {
	flex: 1; }

.loading-overlay {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	justify-self: stretch;
	align-self: stretch;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	// background-color: yellow
	background-color: rgba(255, 255, 255, 0.7);
	// border: dashed 1px orange
	z-index: $loading-overlay-zindex;
	box-sizing: content-box; }

.bp3-dark {
	.bp3-button:not([class*=bp3-intent]) {
		.ikon {
			color: white; } } }

.ikon {
	@extend .bp3-icon; }

// .top-portal
/// z-index: 100

.bp3-menu.dropdown {
	max-height: 70vh;
	overflow: auto; }

.bp3-control.bp3-checkbox input:checked~.bp3-control-indicator:before {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E"); }

.bp3-control.bp3-checkbox input:indeterminate~.bp3-control-indicator:before {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='white'/%3e%3c/svg%3e"); }
