@import 'variables';

.bp3-dialog.katal-dialog {
    .bp3-dialog-header {
        min-height: 30px;
        font-weight: bold;
        user-select: none;
        color: $dark-gray5;

        .ikon.dialog-icon {
            margin-right: 10px; } }

    &.intent-danger .bp3-dialog-header .ikon {
        color: $pt-intent-danger; }

    &.intent-primary .bp3-dialog-header .ikon {
        color: $pt-intent-primary; }

    &.intent-warning .bp3-dialog-header .ikon {
        color: $pt-intent-warning; }

    &.intent-success .bp3-dialog-header .ikon {
        color: $pt-intent-success; } }
