@import "katal3-react/sass/katal-app";

$menu-item-alpha: 0.1;

#app-content > .bp3-card {
    @extend .bp3-elevation-2;
    border-radius: 5px;
    width: 40vw;
    position: relative;
    overflow: hidden;
    background-color: transparent;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        // z-index: -1
        background-image: url('../favicon.svg');
        background-repeat: no-repeat;
        background-position: top right;
        background-blend-mode: soft-light;
        background-size: 128px;
        transform: rotate(-30deg) translate(10px,-30px);
        transform-origin: top right;
        opacity: 0.08; }

    & > .header {
        & > h5 {
            margin: 0;
            font-size: 2em;
            user-select: none; } }

    & > .list {
        & > .bp3-menu {
            background-color: transparent;

            a.bp3-menu-item {
                padding: 8px 12px;
                align-items: center;
                margin: 2px 0;
                border-radius: 5px;

                &.taro:hover {
                    background-color: change-color($taro-color, $alpha: $menu-item-alpha); }

                &.echo:hover {
                    background-color: change-color($echo-color, $alpha: $menu-item-alpha); }

                &.appro:hover {
                    background-color: change-color($appro-color, $alpha: $menu-item-alpha); }

                &.trator:hover {
                    background-color: change-color($trator-color, $alpha: $menu-item-alpha); }

                &.driver:hover {
                    background-color: change-color($red2, $alpha: $menu-item-alpha); }

                & > img:is(:first-child) {
                    width: 24px; }

                & > .bp3-fill {
                    font-size: 1.25em;
                    letter-spacing: 0.05em; }

                & > .bp3-menu-item-label {
                    font-size: 0.95em;
                    color: $gray3; } } } } }
